@import 'assets/scss/mixin.scss';

.budget-details-wrapper {
  padding-right: 3rem !important;

  .cursorpointer {
    cursor: pointer;
  }

  .app-header-search {
    width: 45%;
  }

  .details-header-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }

  .details-left {
    gap: 20px;
    width: 70%;

    .detail-header {
      font-family: 'Open Sans';
      font-size: 24px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0;
      text-align: left;
      color: #222;
    }

    .adv-chk {
      border: 2px solid #123051;
    }

    .advanced-srch {
      font-family: 'Open Sans';
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.02em;
      text-align: left;
      color: #123051;
    }
  }

  .details-right {
    gap: 5px;

    .new-folder {
      background: linear-gradient(135deg, #ff9912 0%, #ff8000 100%);
      box-shadow: 0 8px 16px 0 rgb(189 96 2 / 24%);
      border-color: transparent;
      height: 2.5rem;
      border-radius: 4px;
      line-height: calc(100rem * 18 / 1440);
      gap: 4px;
      text-align: center;
      color: #fff;
      padding: 10px;

      // width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;

      // line-height: calc(100vw * 20 / 1440);
      font-family: 'Open Sans', sans-serif;
      font-weight: 700;
    }
  }

  .admin-users-body {
    width: 100%;
    margin: auto;
    border-radius: 10px;

    // background: #fff;
    box-shadow: 0 8px 16px 0 rgb(0 65 191 / 16%);
    height: auto;

    // overflow-y: auto;

    .selected-row {
      background: linear-gradient(
        0deg,
        rgb(66 133 244 / 40%),
        rgb(66 133 244 / 40%)
      );
    }

    .uname-cell {
      display: flex;
      align-items: center;
      margin-left: 5px;
      // background-color: #000;
    }
    .uname-cell-fringe-group {
      display: flex;
      align-items: center;
      margin-left: -30px;
      // background-color: #000;
    }
    

    .cursorpointer {
      cursor: pointer;
      // background-color: #000;
    }

    .folder-actions-wrapper {
      display: flex;
      gap: 2px;
      align-items: center;
      // background-color: black;
    }

    tbody {
      tr {
        height: 64px;
        border-bottom: 1px solid #888;     
      }
      tr:last-child {
        border-bottom: none;
      }
    }
    .table-scroll{
      max-height: 300px;
      overflow-y: auto;

    }

    .table-scroll-group {
      max-height: 300px;
      overflow-y: auto;
      // background-color: #000;
      td:last-child{
        & > div{
            display: flex;
            justify-content: center;
            max-width: 200px;
            // background-color: black;
          }
        }
        td:nth-child(2){
          min-width: 300px;
        }
        td:nth-child(3){
          max-width: 100px;

        }

    }

    .table-container-fringe-groups {
      width: 100%;
      overflow: auto;
      scroll-behavior: smooth;
      display: flex;
      border-radius: 10px 10px 0 0;
      height: auto;
      max-height: calc(100vh - 19rem);
      overflow-y: auto;
      @include scroll-bar-style; 
    
    }
    

    .table-new-height {
      max-height: calc(100vh - 15rem) !important;
    }
  }

  .app-users-table-head{
    background: #4285f4;
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -1;
    text-align: left;
    color: white;
    height: 44px;


  }
  .app-users-table-head-group {
    background: #4285f4;
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -1;
    text-align: left;
    color: white;
    height: 44px;

      th:last-child {
        text-align: center;
      }

}
  
  .no-budget-found {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-style: dashed;
    padding: 50px;
    height: 240px;
    border-radius: 10px;
    gap: 10px;

    .no-found-head {
      font-family: 'Open Sans';
      font-size: 24px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0;
      text-align: center;
      color: #4d5abc;
    }

    .no-found-body {
      font-family: 'Open Sans';
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0;
      text-align: center;
      color: #666;
    }

    .no-found-res-body {
      font-family: 'Open Sans';
      font-size: 26px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0;
      text-align: center;
      color: #666;
    }

    .new-folder {
      background: linear-gradient(135deg, #ff9912 0%, #ff8000 100%);
      box-shadow: 0 8px 16px 0 rgb(189 96 2 / 24%);
      border-color: transparent;
      height: 2.5rem;
      border-radius: 4px;

      // line-height: calc(100vw * 18 / 1440);
      gap: 4px;
      text-align: center;
      color: #fff;
      padding: 10px;

      // width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: calc(100rem * 16 / 1440);
      line-height: calc(100rem * 20 / 1440);
      font-family: 'Open Sans', sans-serif;
      font-weight: 700;
    }
  }

  .selected-records-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    height: 48px;
    border-radius: 10px;
    background: #e2e2e2;
    align-items: center;
    gap: 10px;
    padding: 0 10px;

    .sel-rec-item-count {
      display: flex;
      align-items: center;
      gap: 10px;

      svg {
        cursor: pointer;
      }
    }

    .actions-wrapper {
      display: flex;
      align-items: center;
      gap: 10px;

      svg {
        cursor: pointer;
      }
    }
  }

  .folders-path {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 10px 0;
    

    .arrow {
      color: #666;
    }

    .folder-data {
      display: flex;
      align-items: center;
      gap: 6px;
    }
  }

  .user-pic-wrapepr {
    display: inline-block;
    height: 36px;
    width: 36px;
    margin-right: -15px;
    margin-left: 6px;
    

    .user-img {
      border-radius: 50%;
      width: 36px;
      height: 36px;
    }

    .user-initials {
      position: relative;
      border-radius: 50%;

      /* width: 36px; */

      /* padding: 11px; */
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      z-index: 1;
      font-family: 'Open Sans';
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0;
      text-align: center;
    }
  }

  .first-collaborator {
    height: 36px;
    width: 36px;
    position: relative;
    display: inline-block;
    border-radius: 50%;
    border: 3px solid #34a853;
    background: white;

    .user-img {
      border-radius: 50%;
      width: 36px;
      height: 36px;
    }

    .user-initials {
      position: relative;
      border-radius: 50%;

      /* width: 36px; */

      /* padding: 11px; */
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      z-index: 1;
      font-family: 'Open Sans';
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0;
      text-align: center;
    }
  }

  .collaborators-container {
    display: flex;

    .dropdown-menu {
      min-width: 60px !important;
      text-align: center;
      width: 40px !important;

      a {
        text-align: center;
        padding: 2px 0 !important;

        .user-pic-wrapepr {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }

  .dropdown-toggle-collaborators {
    background: transparent !important;
    border: none !important;
    display: inline-block !important;
  }

  .first-collaborator-img {
    height: 36px;
    width: 36px;
    position: relative;
    display: inline-block;
    border-radius: 50%;
    border: 3px solid #34a853;
    background: white;

    .user-img {
      border-radius: 50%;
      width: 100%;
      height: 100%;
      padding: 2px;
    }

    .user-initials {
      position: relative;
      border-radius: 50%;

      /* width: 36px; */

      /* padding: 11px; */
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      z-index: 1;
      font-family: 'Open Sans';
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0;
      text-align: center;
    }
  }

  .collaborators-dropdown {
    display: inline-block;
    margin-left: 10px;
    cursor: pointer;
  }

  .first-collaborator-img::before {
    content: '';
    position: absolute;
    inset: 2px;
    border-radius: 50%;
    background: transparent;
    
  }

  .first-collaborator::before {
    content: '';
    position: absolute;
    inset: 2px;
    border-radius: 50%;
    background: linear-gradient(0deg, #34a853, #34a853),
      linear-gradient(0deg, #fff, #fff);
  }

  .edit-icon-folder {
    margin-left: 5px;
  }

  .sorted-icon-highlight {
    background-color: #fff;
    padding: 1px 6px 3px;
    border-radius: 50%;
  }
}

// Variables (Optional for Customization)
$primary-color: #000;
$secondary-color: #f5f5f5;
$active-bg-color: #fff;
$active-border-color: $primary-color;
$hover-bg-color: #e0e0e0;


.contextual-suggestions-container{
  width: 100%;
  // put it at the bottom of the screen
  position: fixed;
  bottom: 30px;
  display: flex;
  justify-content: space-between;
}

.contextual-suggestions {
      padding: 10px 8px;
      padding-left: 1rem;
      background-color: $link-color;
      width: 60%;
      height: 3rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-radius: 100px;

      &__title {
        color: $white-color;
        border-radius: 100px;
        white-space: nowrap;

        @include fs-16;
        @include font-600;
      }

      &__container {
        width: 75%;
        flex: 1;
        display: flex;
        align-items: center;

        .back-icon,
        .forward-icon {
          width: 1.5rem;
          height: 1.5rem;
          background: white;
          border-radius: 50%;
          cursor: pointer;
          margin: 0 0.5rem;

          &.disabled {
            cursor: not-allowed;
          }

          path {
            fill: $black-color;
            fill-opacity: 0.54;
          }

          &:not(.disabled):hover {
            background-color: $theme-color;

            path {
              fill: $white-color;
              fill-opacity: 1;
            }
          }
        }

        &__chip-wrapper {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 0.75rem;
          padding: 2px 0;
          border-radius: 100px;
          width: 90%;
          flex: 1;
          overflow: hidden;
          overflow-x: auto;
          scrollbar-width: none;
          -ms-overflow-style: none;

          &::-webkit-scrollbar {
            display: none;
          }

          .suggestions-chip {
            @include fs-16;
            @include font-600;
          }
        }
      }}
  
.budget-total-wrapper-fringe {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-right: 100px;

      .budget-total {
        height: 3.5rem;
        color: #000432;
        width: max-content;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        gap: 0rem;


        @include fs-18;
        @include font-600;
      }

      .budget-modified {
        color: #565556;
        margin-top: 10px;

        @include fs-12;
        @include font-400;

        &-by {
          color: $link-color;
        }
      }
    }



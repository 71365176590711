.selected-chips {
  display: flex;
  gap: 2px;
}

.fringes{
  width: 20px;

}


.group-chip {
  background-color: #ffffff !important; /* a light blue, for instance */
  color: #000000 !important;

}

.group-chip .chip-close{
  background: none;
  border: none;
  margin-left: 5px;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  color: #000000;
}
.group-chip .chip-close:hover{
  color: #00A3FF;
}
.menu-chip {
  background-color: #00A3FF; /* a light gray */
  color: #ffffff;

}

.group-class {
  background-color: #cce5ff;
}
.chip {
  padding: 3px 6px;
  border: 1px solid #ccc;
  background-color: #00A3FF; /* a light gray */
  color: #ffffff;
  border-radius: 7px;
  font-size: 12px;
}
.chip-popup {
  transform: translateX(-50%);
  background-color: #333;
  color: black;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 10;
}
.my-multi-dropdown .dropdown-menu {
  max-height: 200px;  /* Adjust height as needed */
  overflow-y: auto;   /* Enables vertical scrolling */
  overflow-x: hidden; /* Prevents horizontal scrolling */
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  background-color: #fff;
    
}

.chip-close {
  background: none;
  border: none;
  margin-left: 5px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  color: #fff;
}




.chip-close:hover {
  color: #000;
}

.my-multi-dropdown .dropdown-item {
  font-size: 14px;
  color: #333;
  border-bottom: 1px solid #ccc; /* Add a border between items */
}

.my-multi-dropdown .dropdown-toggle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}


.my-multi-dropdown .dropdown-menu {
  padding: 0;
  border: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  max-height: 200px;
  margin-top: 0.5rem;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 7px;
  width: 80px;
}

.my-multi-dropdown .dropdown-toggle {
    background-color: rgba(255, 255, 255, 0);
    color: black;
    border: 0px solid rgb(255, 255, 255);
    margin-left: -4px;
    
}


.custom-icon {
  font-size: 16px; /* Adjust size */
  font-weight: bold; /* Make it bold *//* Add space between text and icon */
  color: #333; /* Adjust color */
  cursor: pointer; /* Ensure it's clickable */
  color: #00A3FF;
  

}
.my-multi-dropdown .dropdown-toggle::after {
  content: none !important; /* Removes the default dropdown arrow */

  
}

.my-multi-dropdown .btn-primary {
    color: rgb(0, 0, 0);
    --bs-btn-active-bg: #d3d3d3;
    --bs-btn-active-color: #000;
    --bs-btn-hover-bg:  #e7e7e7;
    --bs-btn-hover-color: #000;

  
}



.my-multi-dropdown .dropdown-item.active {
  background-color: #c2c2c29a; /* Change this to your desired color */
  color: #111; /* Optional: Change text color */

}

.my-multi-dropdown .dropdown-item:hover {
  background-color: #969595b7; /* Lighter shade on hover */
  color: #000; /* Text color on hover */
}
.my-multi-dropdown .dropdown-item {
  font-size: 14px; /* Adjust font size */
  color: #333; /* Adjust text color */
  border-bottom: 1px solid #ccc;
   /* Add a border between items */
}

.my-multi-dropdown .dropdown-item {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px 12px;
  /* other styles */
}


/* .col-content .my-multi-dropdown {
  width: 87px; 
}

.col-content .my-multi-dropdown.expanded {
  width: auto !important;
}
*/
.totalCost{
  margin: 0px 0px 0px 40px ;
} 

.dropdown-add-item {
  /* match the checkbox item styles */
  text-align: center;

  font-weight: 500;

  background-color: #f8f9fa;
  cursor: pointer;


  &:hover {
    background-color: #e2e6ea;
    color: #0056b3;
  }
}

.dropdown-add-item .plus-icon {
  font-size: 1.1rem;
  line-height: 1;
  color: #007bff;
}

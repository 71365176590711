@import 'assets/scss/mixin.scss';
@import 'assets/scss/variables.scss';

.budget-container {
  padding: 1.5rem 0;
  height: 100%;
  position: relative;
  width: 100%;

  &__footer {
    height: 6rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 1rem;

    .contextual-suggestions {
      padding: 10px 8px;
      padding-left: 1rem;
      background-color: $link-color;
      width: 60%;
      height: 3rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-radius: 100px;

      &__title {
        color: $white-color;
        border-radius: 100px;
        white-space: nowrap;

        @include fs-16;
        @include font-600;
      }

      &__container {
        width: 75%;
        flex: 1;
        display: flex;
        align-items: center;

        .back-icon,
        .forward-icon {
          width: 1.5rem;
          height: 1.5rem;
          background: white;
          border-radius: 50%;
          cursor: pointer;
          margin: 0 0.5rem;

          &.disabled {
            cursor: not-allowed;
          }

          path {
            fill: $black-color;
            fill-opacity: 0.54;
          }

          &:not(.disabled):hover {
            background-color: $theme-color;

            path {
              fill: $white-color;
              fill-opacity: 1;
            }
          }
        }

        &__chip-wrapper {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 0.75rem;
          padding: 2px 0;
          border-radius: 100px;
          width: 90%;
          flex: 1;
          overflow: hidden;
          overflow-x: auto;
          scrollbar-width: none;
          -ms-overflow-style: none;

          &::-webkit-scrollbar {
            display: none;
          }

          .suggestions-chip {
            @include fs-16;
            @include font-600;
          }
        }
      }
    }

    .budget-total-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .budget-total {
        height: 3.5rem;
        color: #000432;
        width: max-content;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        gap: 0rem;


        @include fs-18;
        @include font-600;
      }

      .budget-modified {
        color: #565556;
        margin-top: 10px;

        @include fs-12;
        @include font-400;

        &-by {
          color: $link-color;
        }
      }
    }
  }

  .budget-tabs-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .budget-tabs {
      display: flex;
      gap: 0.25rem;

      .budgets-dropdown {
        border-radius: 0.5rem 0.5rem 0 0;
        background: $white-color;
        box-shadow: 0 -4px 4px 0 rgb(0 65 191 / 8%);
        height: 2.5rem;

        @include fs-14;
        @include font-400;

        .dropdown-menu {
          min-width: max-content;

          // min-width: 120px;
          max-height: 400px;
          overflow-y: auto;
          @include scroll-bar-style;

          background: white;
        }
      }

      .budget-tab {
        border-radius: 0.5rem 0.5rem 0 0;
        background: $white-color;
        box-shadow: 0 -4px 4px 0 rgb(0 65 191 / 8%);
        height: 2.5rem;
        display: flex;
        padding: 0.875rem;
        justify-content: space-between;
        align-items: center;
        color: $input-color;
        gap: 1rem;
        cursor: pointer;

        @include fs-14;
        @include font-400;

        .app-dropdown {
          display: none;
        }

        &__expand-icon {
          vertical-align: unset;
          width: 1.125rem;
          height: 1.125rem;
        }

        &__add-icon {
          vertical-align: unset;
          width: 1.5rem;
          height: 1.5rem;
        }

        &.budget-tab-active {
          color: $white-color;
          background-color: $link-color;

          .app-dropdown {
            background-color: transparent;
            display: block;

            .dropdown-menu {
              min-width: max-content;
            }

            &-toggle {
              padding: 0;
            }
          }
        }

        &.budget-tab-new-budget {
          color: $link-color;
          gap: 0;

          @include font-600;
        }
      }
    }

    .collaborators {
      display: flex;
      gap: 0.875rem;

      &-icon {
        position: relative;
        z-index: 2;
        width: 2rem;
        height: 2rem;
        padding: 0;
        border: 0;
        margin: 0;
        opacity: 0.5;

        .avatar-icon {
          width: 100%;
          height: 100%;
        }

        &.collaborators-icon-active {
          opacity: 1;

          &::after {
            position: absolute;
            width: 2.25rem;
            height: 2.25rem;
            border: 1px solid green;
            background-color: white;
            border-radius: 50%;
            z-index: -1;
            content: ' ';
          }
        }
      }

      .collaborators-expand {
        .collaborators-dropdown {
          width: 2rem;
          height: 2rem;
          padding: 0;
          background-color: $white-color;
          border-radius: 50%;
          box-shadow: 0 4px 4px rgb(0 65 191 / 16%);
        }
      }

      .share-budget-button-wrapper {
        // width: 120px;

        .share-budget-button {
          height: 2rem;
          padding: 10px;
          border-radius: 4px;

          @include fs-14;
        }
      }
    }
  }

  .budget-tab-content {
    width: 100%;
    height: calc(100% - 8rem);
    border-radius: 0 10px 10px;
    background: $white-color;
    box-shadow: 0 8px 16px 0 rgb(0 65 191 / 16%);

    &.no-budgets {
      width: 100%;
      height: calc(100% - 2.5rem);
      display: flex;
      justify-content: center;
      align-items: center;

      .no-budgets-card {
        padding: 2.5rem;
        border-radius: 1.25rem;
        border: 2px dashed $link-color;
        background: $white-color;

        .card-header {
          color: $link-color;
          margin-bottom: 0.75rem;
          text-align: center;

          @include fs-24;
          @include font-600;
        }

        .card-body {
          color: $semi-grey-color;
          margin-bottom: 2rem;
          text-align: center;

          @include fs-16;
          @include font-600;
        }

        .card-action {
          display: flex;
          gap: 0.875rem;
          justify-content: center;
          align-items: center;
          width: 100%;

          .create-budget-button,
          .import-budget-button {
            height: 2.5rem;
            padding: 10px;
          }
        }
      }
    }

    .budget-swimlane-block {
      width: 100%;
      height: 100%;
      overflow: auto;
      scroll-behavior: smooth;
      display: flex;
      position: relative;
      border-radius: 0 10px 10px;

      @include scroll-bar-style(40px);

      .budget-swimlane-nav-block {
        display: inline-flex;
        align-items: center;
        height: 2.5rem;
        border-radius: 0 10px 0 0;
        min-width: 100%;
        background-color: $link-color;
        position: absolute;

        .budget-swimlane-nav {
          width: 100%;
          display: flex;
          justify-content: flex-end;

          .swimlane-name {
            display: flex;
            color: $white-color;
            align-items: center;
            cursor: pointer;
            gap: 0.5rem;
            margin-right: 2rem;

            @include font-600;
            @include fs-16;
          }
        }

        .budget-swimlane-details-nav {
          width: 100%;
          min-width: max-content;
          display: flex;
          position: relative;

          .swimlane-name,
          .close-detailed-view {
            display: flex;
            color: $white-color;
            align-items: center;
            cursor: pointer;
            gap: 0.5rem;
            margin-right: 1rem;
            width: max-content;

            @include font-600;
            @include fs-16;
          }

          .close-detailed-view {
            margin: 0;
            align-self: center;
            position: sticky;
          }
        }
      }

      .budget-swimlane-wrapper {
        margin-top: 2.5rem;
        height: calc(100% - 2.5rem);

        .budget-swimlane-container {
          flex: 1;
          width: max-content;
          height: 100%;
          display: flex;
          flex-wrap: nowrap;
          overflow: hidden;
          overflow-y: auto;
          background-color: $white-color;
          border-radius: 0 0 10px 10px;

          .react-resizable {
            flex-shrink: 0;
            min-height: 100% !important;
            height: 100% !important;
            border-right: 1px solid #000432;
            overflow: hidden;

            .budget-swimlane {
              height: calc(100% + 5px);
              overflow: hidden;
              overflow-y: auto;

              @include scroll-bar-style;
            }

            .swimlane-item {
              width: 100%;
              display: flex;
              position: relative;
              justify-content: space-between;
              align-items: center;
              color: $text-color;
              padding: 8px;
              cursor: pointer;

              @include app-width(min-height, 40);
              @include fs-14;
              @include font-400;

              &--disabled {
                cursor: not-allowed;
                pointer-events: none;
              }

              &--add-input {
                height: auto;
                justify-content: stretch;
              }

              &--action {
                position: relative;
                flex-direction: column;
                align-items: flex-end;
                justify-content: center;
                gap: 0.75rem;

                .action-add-details {
                  display: flex;
                  align-items: center;
                  cursor: pointer;
                  gap: 5px;

                  &__icon {
                    vertical-align: unset;
                    @include app-width(width, 18);
                    @include app-width(height, 14);

                    path {
                      fill: $link-color;
                    }
                  }
                }

                .action-add-item {
                  display: flex;
                  align-items: center;
                  cursor: pointer;

                  &__icon {
                    vertical-align: unset;
                    @include app-width(width, 20);
                    @include app-width(height, 20);

                    path {
                      fill: $link-color;
                    }
                  }
                }
              }

              &--odd {
                background-color: #f6f6f6;
              }

              &--even {
                background-color: $white-color;
              }

              &--active {
                opacity: 0.5;
                width: 100%;
                position: absolute;
                left: 0;
                top: 0;
                background-color: $link-color;
                z-index: 0;
                height: 100%;
              }

              &__info {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex: 1;
                z-index: 1;
                position: relative;

                .item-name-wrapper {
                  width: 75%;
                  display: flex;
                  align-items: center;

                  .item-name {
                    max-width: 90%;
                    white-space: break-spaces;
                  }

                  .item-icon {
                    svg {
                      @include app-width(height, 21);
                      @include app-width(width, 20);
                    }
                  }
                }
              }

              &__options {
                .app-dropdown {
                  background: transparent;

                  &-toggle {
                    padding: 0;
                  }
                }

                .more-icon {
                  vertical-align: unset;
                  @include app-width(width, 20);
                  @include app-width(height, 20);

                  path {
                    fill: $link-color;
                  }
                }
              }
            }
          }
        }
      }

      .budget-swimlane-details-wrapper {
        margin-top: 2.5rem;
        flex: 1;

        .budget-swimlane-details-container {
          flex: 1;
          width: 100%;
          height: 100%;
          display: flex;
          flex-wrap: nowrap;
          overflow: hidden;
          overflow-y: auto;
          background-color: $white-color;
          border-radius: 0 0 10px 10px;

          @include scroll-bar-style;
        }
      }
    }
  }
}

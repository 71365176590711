.create-budget {
  .form-select {
    max-height: 60px; // Approximate height for two options (adjust as needed)
    overflow-y: auto; // Enable vertical scrolling for extra options
    overflow-x: hidden; // Prevent horizontal scrolling
    position: relative; // Ensure proper positioning
    background-color: #fff; // Ensure the dropdown has a white background
    border: 1px solid #ccc; // Add a border for better visibility
    border-radius: 4px; // Optional: Add rounded corners
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); // Optional: Add a subtle shadow

    option {
      padding: 8px 12px; // Add padding for better spacing
      white-space: nowrap; // Prevent text wrapping
      text-overflow: ellipsis; // Add "..." for long text
      overflow: hidden; // Hide overflowed text
      &:hover {
        background-color: #f5f5f5; // Add hover effect
      }
    }
  }
}
.Type-button{
    font-size: 1.3rem;
    }
 .toggle-button-fringes {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  .toggle-button-fringe {
    display: flex;
    border-radius: 4px;
    background-color: #ffffff; // Light background color
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s, color 0.3s, border 0.3s;


    .toggle-button-one {
      background-color: #d3d3d3; // Default background color
      transition: background-color 0.3s, color 0.3s; // Smooth transitions
      border: 1px solid transparent; // Default border for consistency

      &:hover {
        background-color: #dadadac4; // Slightly darker grey on hover
      }

      &.active {
        background-color: #fff; // White when active
        color:white; // Black text color when active // Add a solid black border when active
      }
    }
  }
}

.css-1pahdxg-control {
  z-index: 1000 !important;
}

// Fix issue where dropdown gets cut off
.css-26l3qy-menu {
  max-height: 200px !important;
  overflow-y: auto !important;
}


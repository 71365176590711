.fringe-group-toggle {
  display: flex;
  background-color: #E7E7E7;
  font-family: 'Open Sans';
  padding: 0.4rem 0.4rem;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: black;
  border-radius: 8px;
}

.fringes-groups-parent {
  display: flex;
  position: absolute;
  left: 15rem;
}
.fringe-group{
  display: flex;
  gap: 10px;
  padding: 0.3rem 1.2rem;
  border:0px;
  background-color: #E7E7E7;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.fringe-group.active {
  background-color: #FFFFFF;


}